angular.module('app', [
  'ngRoute',
  'kv.router',
  'kv.oauthclient',
  'kv.bankid',
  'kv.alerts',
  'kv.goback',
  'kv.agreement',
  'kv.coreapi',
  'ngRaven',
  'ngMessages',
  'ngFile'
])
  .config(appConfig)
  .controller('CreateAgreementCtrl', appCreateCtrl)
  .controller('ManageAgreementCtrl', appManageCtrl)
  .controller('AgreementReceiptCtrl', appReceiptCtrl)
  .run(setRoutes)
  .run(appRun);

