function appReceiptCtrl(
  $scope,
  $interpolate,
  $route,
  $location,
  DICT,
  Agreement
) {

  $scope.receiptTitleStart = DICT['agreements__receipt_title--start'];
  $scope.receiptTitlePart = DICT['agreements__receipt_title--part'];
  $scope.receiptTitlePartPlural = DICT['agreements__receipt_title--part_plural'];
  $scope.receiptTitle = $scope.receiptTitleStart + ' {{ parties.length + user_parties.length }} {{ parties.length + user_parties.length > 1 ? "' + $scope.receiptTitlePartPlural + '" : "' + $scope.receiptTitlePart + '" }}';

  function getTemplated(string) {
    return $interpolate(string)(Agreement);
  }

  function goToManage () {
    $location.path([
      '/manage',
      $route.current.params.contentKey,
      $route.current.params.agreementKey
    ].join('/'));
  }
    
  
  angular.extend($scope, {
    agreement: Agreement,
    template: getTemplated,
    DICT: DICT,
    goToManage: goToManage,
    signSteps: [ DICT['agreements__receipt_step_1'], DICT['agreements__receipt_step_2'], DICT['agreements__receipt_step_3']]
  });
  
}
