/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */

function ApiContentFactory(
  $http,
  $q,
  $sessionStorage,
  CoreApiReqFactory
) {

  var sessionData = $sessionStorage.content;

  function clear () {
    sessionData = undefined;
  }

  function getAll () {
    var dfd = $q.defer();
    var url = CoreApiReqFactory.getBaseUrl(['content']);

    if (sessionData) {
      dfd.resolve(sessionData);
    } else {
      $http
        .get(url)
        .then(function (res) {
          sessionData = res.data;
          dfd.resolve(res.data);
        })
        .catch(function (err) {
          if (err.id) {
            dfd.resolve(err);
          } else {
            dfd.reject(err);
          }
        });
    }

    return dfd.promise;
  }

  function get (ckey) {
    var dfd = $q.defer();
    
    var url = CoreApiReqFactory.getBaseUrl([
      'content',
      ckey
    ], {
      apiVersion: 'v2'
    });

    if (sessionData) {
      dfd.resolve(sessionData);
    } else {
      $http
        .get(url)
        .then(function (res) {
          sessionData = res.data;
          dfd.resolve(res.data);
        })
        .catch(function (err) {
          if (err.id) {
            dfd.resolve(err);
          } else {
            dfd.reject(err);
          }
        });
    }

    return dfd.promise;
  }

  function create (payload) {

    return $http.post(
      CoreApiReqFactory.getBaseUrl(['content']),
      payload
    );
  }
  
  return {
    getUserContents: getAll,
    get: get,
    create: create,
    clear: clear
  };

}
