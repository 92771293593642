function appRun(
  $location,
  $log,
  $rootScope,
  CONFIG,
  GoBackFactory,
  KvQueryString,
  oauthclientConfig,
  OAuthFactory,
  Raven
) {
  $log.debug('[main]\t\tInitiating app:\tENV=' + CONFIG.env);

  var hashParams = KvQueryString.parse($location.hash());

  // set url to go back to if provided in fragment
  GoBackFactory.set({
    url: hashParams.redirect_uri,
    label: hashParams.redirect_label
  });

  function reAuthenticate(params) {
    OAuthFactory.authenticate({
      params: params
    });
  }

  // Let user sign in again if kv.oauthclient module says it's unauthorized
  $rootScope.$on(oauthclientConfig.EVENT_ERR_UNAUTHORIZED, function() {
    reAuthenticate({
      state: $location.path()
    });
  });

  // Let user sign in again if kv.oauthclient module says token validation failed
  $rootScope.$on(oauthclientConfig.EVENT_ERR_TOKENVALIDATE, function(
    event,
    err
  ) {
    reAuthenticate();
  });

  if (CONFIG.sentry_dsn_kivra_sign) {
    var userKey = OAuthFactory.getUkey();
    if (userKey) {
      Raven.setUser({
        id: userKey
      });
    }
    $rootScope.$on(oauthclientConfig.EVENT_SESSION_INIT, function(event, data) {
      Raven.setUser({
        id: data.resourceOwner
      });
    });
  }
}
