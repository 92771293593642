'use strict';

(function () {
  angular.module('heimdall.device')
    .factory('DeviceFactory', factory);
  
  function factory (
    $window,
    $log
  ) {

    function getDevice() {
      var agent = $window.navigator.userAgent;
      return {
        isMobile: (/(Android|iPhone|iPad)/).test(agent),
        isAndroid: (/Android/).test(agent),
        isChrome:  (/Chrome/).test(agent),
        // need a better way of determining if the user is coming from our app
        // isKivraApp: (/kivra\:\/\//).test(OAuthFactory.params.redirect_uri),
        isIOS: /(iPad|iPhone|iPod)/g.test(agent),
        isCriOS: /CriOS/.test(agent)
      };
    }
    
    var factory = {
      getDevice: getDevice
    };
     
    return factory;
  }

})();
