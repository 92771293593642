function CoreApiReqFactory(
  $http,
  OAuthFactory,
  coreAPI
) {

  var factory = {
    getBaseUrl: getBaseUrl
  };
  
  function getBaseUrl(paths,opt) {
    opt = opt || {};
    var paths = paths || [];
    return [
      coreAPI.coreHost,
      opt.apiVersion || coreAPI.apiVersion,
      'user',
      OAuthFactory.getUkey()
    ].concat(paths).join('/');
  }
  
  return factory;
}
