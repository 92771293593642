/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */

function ApiAgreementFactory(
  $http,
  $q,
  $log,
  $sessionStorage,
  $route,
  CoreApiReqFactory
) {

  var sessionData = $sessionStorage.agreements || {};

  // Public methods
  // ----------------------

  var factory = {
    get: get,
    initSign: initSign,
    sign: sign,
    cancel: cancel,
    clear: clear
  };

  // clear stored session data
  function clear () {
    sessionData = {};
  }

  // Get an agreement object
  function get (ckey, akey) {

    var url = _getAgreementUrl(ckey, akey);
    
    return $q(function (resolve, reject) {
    
      if (sessionData[ckey]) {
        resolve(sessionData[ckey]);
      }
    
      $http
        .get(url)
        .then(function (res) {
          sessionData[akey] = res.data;
          resolve(res.data);
        }, function (err) {
          reject(err);
        });
      
      });
  }

  function cancel (ckey, akey) {

    var dfd = $q.defer();
    
    var url = CoreApiReqFactory.getBaseUrl([
      'content',
      ckey,
      'agreement',
      akey,
      'cancel'
    ], {
      apiVersion: 'v2'
    });

    $http.post(url, {})
      .then(function (res) {
        // store in sessionstorage
        sessionData[akey].status = 'canceled';
        dfd.resolve(sessionData[akey]);
      }, function (err) {
        dfd.reject(err);
      });

    return dfd.promise;
    
  }

  function initSign (opt) {

    var dfd = $q.defer();

    var url = _getPartyUrl(opt.contentKey, opt.agreementKey, opt.partyKey) + '/init';

    $http.post(url, {})
      .then(function (res) {
        dfd.resolve({
          orderKey: res.headers('kivra-objkey')
        });
      }, function (err) {
        dfd.reject(err);
      });
      
    return dfd.promise;
    
  }

  function sign (opt) {

    var dfd = $q.defer();
    
    var url = _getPartyUrl(opt.contentKey, opt.agreementKey, opt.partyKey) + '/sign';
    var akey = opt.agreementKey;

    function updateSignData (cur, party) {
      if (party.key === cur.key) {
        party.signdata = cur.signdata;
      }
    }
    
    $http
      .post(url, {
        order_key: opt.orderKey
      })
      .then(function (res) {

        var completed = true;

        function readParty(party) {
          updateSignData(res.data, party);
          if (!party.signdata) {
            completed = false;
          }
        }

        sessionData[akey].parties.forEach(readParty);
        sessionData[akey].user_parties.forEach(readParty);
        sessionData[akey].status = (completed) ? 'completed' : 'in_progress';
        dfd.resolve(sessionData[akey]);
      });

    return dfd.promise;

  }

  // Private utility methods
  // ----------------------

  function _getAgreementUrl(ckey, akey) {

    return CoreApiReqFactory.getBaseUrl([
      'content',
      ckey,
      'agreement',
      akey
    ], {
      apiVersion: 'v2'
    });

  }

  function _getPartyUrl(ckey, akey, pkey) {

    return [
      _getAgreementUrl(ckey, akey),
      'party',
      pkey
    ].join('/');

  }

  return factory;

}
