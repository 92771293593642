function appConfig(
  $httpProvider,
  $locationProvider,
  $logProvider,
  oauthclientConfigProvider,
  BankIDProvider,
  routerHelperProvider,
  coreAPIProvider,
  RavenProvider,
  CONFIG,
  RELEASE
) {
  $locationProvider.html5Mode({
    enabled: true
  });
  oauthclientConfigProvider.set({
    clientHost: CONFIG.kivra_sign_origin,
    redirectRoute: CONFIG.oauth_redirect_route,
    endpointUri: CONFIG.oauth_endpoint_url,
    clientId: CONFIG.oauth_client_id__kivra_sign,
    responseType: CONFIG.oauth_response_type,
    apiUrl: CONFIG.core_api_origin,
    tokenInfoUrl: CONFIG.oauth_tokeninfo_url
  });

  oauthclientConfigProvider.addAuthInterceptor($httpProvider);

  coreAPIProvider.set({
    coreHost: CONFIG.core_api_origin,
    apiVersion: CONFIG.core_api_version
  });

  $logProvider.debugEnabled(CONFIG.debug);

  routerHelperProvider.setDefaultPath('/create');

  BankIDProvider.setAPIUrl(CONFIG.core_api_origin);

  if (CONFIG.sentry_dsn_kivra_sign) {
    RavenProvider.config(CONFIG.sentry_dsn_kivra_sign, {
      release: RELEASE.hash,
      tags: {
        git_commit: RELEASE.hash,
        git_tag: RELEASE.tag,
        host: CONFIG.kivra_sign_origin
      },
      dataCallback: function(data) {
        // Remove fragment
        data.request.url = data.request.url.replace(/#.+$/, '');

        return data;
      }
    }).install();
  }
}
