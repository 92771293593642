/* 
 * Factory to wrap http interactions to the bank part of core's api.
 * It's methods returns promises resolving to result objects
 */

function ApiFileFactory (
  CoreApiReqFactory,
  OAuthFactory
) {

  function getUrl (opt) {
    return CoreApiReqFactory.getBaseUrl([
      'content',
      opt.contentKey,
      'file',
      opt.fileKey,
      'raw',
      opt.fileName
    ], {
      apiVersion: 'v1'
    }) + '?access_token=' + OAuthFactory.getToken();
  }
  
  return {
    getUrl: getUrl
  };

}
