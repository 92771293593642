// Fetch remote config before adding the angular-app element to the DOM
var mainAppModule = angular.module('app');

// Fetch remote config before adding the angular-app element to the DOM
var fetchConfig = function() {
  var initInjector = angular.injector(['ng']),
    $http = initInjector.get('$http'),
    $q = initInjector.get('$q');

  var fetchPromises = $q.all([
    $http.get('/release.json'),
    $q(function(resolve, reject) {
      $http
        .get(window.CONFIG_URL)
        .then(resolve)
        // If unsuccessful get local.
        .catch(function() {
          $http
            .get('/config-default.json')
            .then(resolve)
            .catch(reject);
        });
    })
  ]);

  return $q(function(resolve, reject) {
    fetchPromises
      .then(function(res) {
        mainAppModule.constant('RELEASE', res[0].data);
        mainAppModule.constant('CONFIG', res[1].data);

        resolve({
          RELEASE: res[0].data,
          CONFIG: res[1].data
        });
      })
      .catch(function() {
        initInjector
          .get('$log')
          .warn(
            '[WARNING] Could not get remote config file config.json! Using Default ...'
          );
        reject();
      });
  });
};

// Fetch remote copy
function fetchCopy (data) {
  var CONFIG = data.CONFIG;
  var initInjector = angular.injector(['ng']),
    $http = initInjector.get('$http'),
    $q = initInjector.get('$q');


  var urls = {
    'sv-se': CONFIG['kivra_copy_url_sv-se'],
    'en-US': CONFIG['kivra_copy_url_en-US'],
    'fi-FI': CONFIG['kivra_copy_url_fi-FI'],
    'sv-FI': CONFIG['kivra_copy_url_sv-FI']
  };

  var url = urls[CONFIG['kivra_locale_fallback_locale']];

  return $q(function (resolve, reject) {
    $http.get(url)
      .then(function (res) {
        mainAppModule.constant('DICT', res.data);
        resolve();
      })
      .catch(function() {
        $http.get('/copy-default.json')
          .then(function (res) {
            mainAppModule.constant('DICT', res.data);
            resolve();
          })
          .catch(reject);
      });
  });
}

var bootstrapApp = function() {
  angular.element(document).ready(function() {
    angular.bootstrap(document.body, [mainAppModule.name]);
  });
};

// Trigger app initialization!
fetchConfig().then(fetchCopy).then(bootstrapApp);
