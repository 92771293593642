function appCreateCtrl(
  $scope,
  $log,
  $window,
  $location,
  $timeout,
  $interpolate,
  User, // resolved obj
  GoBackFactory,
  AlertsFactory,
  AgreementFactory,
  CONFIG,
  DICT
) {

  $scope.files = [];

  $scope.agreement = {
    data: null,
    subject: '',
    parties: []
  };

  $scope.agreementStart = DICT['agreements__sign_by--start'];
  $scope.agreementPart = DICT['agreements__sign_by--part'];
  $scope.agreementPartPlural = DICT['agreements__sign_by--part_plural'];
  $scope.agreementText = $scope.agreementStart + ' {{ parties.length }} {{ parties.length > 1 ? "' + $scope.agreementPartPlural + '" : "' + $scope.agreementPart + '" }}?';

  angular.extend($scope, {
    createAgreement: createAgreement,
    removeParty: removeParty,
    addUserParty: addUserParty,
    addEmptyParty: addEmptyParty,
    CONFIG: CONFIG,
    DICT: DICT,
    canSend: canSend,
    template: template,
    editParty: null,
    setEditParty: setEditParty
  });

  function template (string) {
    return $interpolate(string)($scope.agreement);
  }

  // focus subject line after choosen file has changed
  $scope.$watch('files', function (newVal) {
    if (newVal.length > 0) {
      $timeout(function () {
        document.getElementById('subject').focus();
      }, 100);
    }
  });

  function setEditParty (party) {
    $scope.editParty = party;
    // focus ssn field if a party is going into edit state
    if (party) {
      $timeout(function () {
        var elID = (party.$$isUser) ? 'name' : 'ssn';
        document.getElementById(elID).focus();
      }, 100);
    }
  }

  function canSend () {
    return [
      $scope.createForm.$valid,
      $scope.agreement.parties.length > 0,
      $scope.files.length > 0,
      $scope.editParty === null
    ].every(function (b) {
      return b;
    });
  }

  
  function addUserParty (index) {
    _addParty({
      name: User.name,
      ssn: User.ssn,
      email: '',
      $$index: index,
      $$isUser: true
    });
  }

  function addEmptyParty (index) {
    _addParty({
      name: '',
      ssn: '',
      email: '',
      $$index: index
    });
  }

  function _addParty (party) {
    $scope.agreement.parties.push(party);
    setEditParty(party);
  }

  function removeParty (party) {
    $scope.agreement.parties.splice($scope.agreement.parties.indexOf(party), 1);
    setEditParty(null);
  }

  function createAgreement (agreement, file) {

    function success (res) {

      $log.debug('Content created', res.contentKey);
      $log.debug('Agrement created', res.agreementKey);

      // go to receipt route
      $location.path([
        'receipt',
        res.contentKey,
        res.agreementKey
      ].join('/'));
    }

    function error (err) {

      console.log(err);
      AlertsFactory.error($scope, err);

      $scope.pending = false;
    }

    function stripUtils (party) {
      return JSON.parse(
        angular.toJson(party)
      );
    }

    $scope.pending = true;
    var opt = {
      // strip utility $$keys from parties
      parties: agreement.parties.map(stripUtils),
      data: file.body,
      fileName: file.name,
      subject: agreement.subject
    }
    AgreementFactory
      .create(opt)
      .then(
        success,
        error
      );
  }

  
}
