function appManageCtrl (
  $scope,
  $location,
  AgreementFactory,
  ApiFileFactory,
  Agreement, // resolved obj
  Content, // resolved obj
  CONFIG,
  DICT,
  $route,
  $interpolate,
  $window,
  BankIDFactory,
  AlertsFactory
) {

  var currentSign = null;

  // Clients can opt-out of print support.
  // We also hide it in Android and iOS
  var noPrint = $location.search()['no_print'] === 'true';
  if (!noPrint && window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase && String.prototype.includes) {
    var ua = window.navigator.userAgent.toLowerCase();
    noPrint = ua.includes('android') || ua.includes('ios')
  }

  function fileURL (file) {
    return ApiFileFactory.getUrl({
      contentKey: $route.current.params.contentKey,
      fileKey: file.key,
      fileName: file.name
    });
  }
  var files = Content
    .parts
    .filter(function (file) {
      return file.content_type === 'application/pdf';
    });

  function error (err) {
    AlertsFactory.error($scope, err);
    $scope.pending = false;
  }

  function bankIdError (errorBody) {
    return error({
      body: errorBody
    });
  }

  function getTemplatedStatus () {
    var str = DICT['agreements__manage_status_in_process--start'] + ' {{ remaining }} '+DICT['agreements__manage_status_in_process--off']+' {{ total }} '+DICT['agreements__manage_status_in_process--signatures'];
    var parties = Agreement.user_parties.concat(Agreement.parties);
    var signatures = parties
      .filter(function (value) {
        return value.signdata !== null;
      })
      .length;
    return $interpolate(str)({
      total: parties.length,
      remaining: parties.length - signatures,
      agreement: Agreement
    });
  }

  function print () {
    $window.print();
  }

  function notify (body) {
    if (body === BankIDFactory.OPEN_BANKIDAPP) {
      BankIDFactory.openBankIDApp({
        dfd: currentSign
      });
    } else {
      AlertsFactory.success($scope, {
        body: body,
        pending: true
      });
    }
  }

  function success (res) {
    AlertsFactory.clear($scope);
    $scope.agreement = res;
  }

  function sign (partyKey) {

    AlertsFactory.clear($scope);

    currentSign = AgreementFactory.sign({
      contentKey: $route.current.params.contentKey,
      agreementKey: $route.current.params.agreementKey,
      partyKey: partyKey
    });

    $scope.currentSign = {
      promise: currentSign,
      partyKey: partyKey
    };

    currentSign.then(
      success,
      bankIdError,
      notify
    );

  }

  function cancel () {
    var confirmText = DICT['agreements__cancel_agreement_confirm--part_1'] + '\n' + DICT['agreements__cancel_agreement_confirm--part_2'];
    if (!$window.confirm(confirmText)) {
      return;
    }
    AgreementFactory
      .cancel({
        contentKey: $route.current.params.contentKey,
        agreementKey: $route.current.params.agreementKey
      })
      .then(function (agreement) {
        $scope.agreement = agreement;
      }, error);
  }

  function getPartyStatus (party) {
    if ((party.signdata || {}).order_key) {
      if ($scope.agreement.status === 'canceled') {
        return 'signed-canceled';
      }
      return 'signed';
    }
    if ($scope.agreement.status === 'canceled') {
      return 'canceled';
    }
    if ($scope.agreement.user_parties.indexOf(party) !== -1) {
      return 'unsigned';
    }
    return 'in_progress';
  }

  angular.extend($scope, {
    content: Content,
    agreement: Agreement,
    getTemplatedStatus: getTemplatedStatus,
    isOwner: AgreementFactory.isOwner(Agreement),
    sign: sign,
    CONFIG: CONFIG,
    DICT: DICT,
    cancel: cancel,
    print: print,
    fileURL: fileURL,
    files: files,
    noPrint: noPrint,
    getPartyStatus: getPartyStatus
  });
  
}
