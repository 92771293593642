'use strict';
(function () {
  
  angular
    .module('kv.router')
    .factory('RouterFactory', RouterFactory);

  function RouterFactory(routerHelper) {
    
    var $routeProvider = routerHelper.config.$routeProvider;

    var service = {
      addRoutes: addRoutes
    };

    return service;
    
    // enables different modules to hook up into the same $routeProvider
    function addRoutes(routes) {
      routes.forEach(function(route) {
        $routeProvider.when(route.url, route.config);
      });
      $routeProvider.otherwise({redirectTo: routerHelper.config.defaultPath});
    }
  }
})();
