'use strict';

/**
 * Module for all kivra user logic
 */

angular.module('kv.settings.user', [
  'kv.coreapi',
  'ngStorage'
]);
// run and config blocks goes here ...
