'use strict';
(function () {
  
  angular
    .module('kv.router')
    .provider('routerHelper', routerHelperProvider);

  function routerHelperProvider($routeProvider) {

    var config = {
      $routeProvider: $routeProvider,
      defaultPath: '/'
    };
    
    function setDefault (path) {
      config.defaultPath = path;
    }

    function $get () {
      return {
        config: config
      };
    }
    
    var provider = {
      config: config,
      setDefaultPath: setDefault,
      $get: $get
    };

    return provider;
    
  }

})();
