'use strict';
angular.module('kv.coreapi', [
  'kv.oauthclient',
  'ngStorage'
])
  .provider('coreAPI', coreApiProvider)
  .factory('CoreApiReqFactory', CoreApiReqFactory)
  .factory('ApiTenantFactory', ApiTenantFactory)
  .factory('ApiUserFactory', ApiUserFactory)
  .factory('ApiBankFactory', ApiBankFactory)
  .factory('ApiFileFactory', ApiFileFactory)
  .factory('ApiAgreementFactory', ApiAgreementFactory)
  .factory('ApiContentFactory', ApiContentFactory);
