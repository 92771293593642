'use strict';

(function () {
  angular.module('kv.settings.user')
    .factory('UserFactory', factory);
  
  function factory (
    $log,
    ApiUserFactory
  ) {

    function updateMMAccount (account) {
      // patch local user object
      ApiUserFactory.updateUser({
        external_accounts: {
          'minameddelanden.se': account
        }
      }, {
        localOnly: true
      });
    }
    
    return {
      updateMMAccount: updateMMAccount
    };
  }

})();
